


<div class="columns">
    <div class="column">

        <div class="field">
            <label class="label">Email</label>
            <div class="control">
                <input class="input" [(ngModel)]="email" type="text" placeholder="Your email">
            </div>
            <p *ngFor="let error of errors" class="help">{{ error }}</p>
        </div>

        <div class="field">
            <label class="label">Password</label>
            <div class="control">
                <input class="input" [(ngModel)]="password" type="password" placeholder="Your password">
            </div>
            <p *ngFor="let error of errors" class="help">{{ error }}</p>
        </div>

        <div class="field">
            <div class="control">
                <label class="checkbox">
                    <input type="checkbox" [(ngModel)]="save">
                    Keep auth
                </label>
            </div>
        </div>


        <div class="control">
            <button class="button is-primary" (click)="login()">Login</button>
        </div>
    </div>

    <div class="column">

        <div class="field">
            <label class="label">Name</label>
            <div class="control">
                <input class="input" [(ngModel)]="name" type="text" placeholder="Your name">
            </div>
            <p *ngFor="let error of errors" class="help">{{ error }}</p>
        </div>

        <div class="field">
            <label class="label">Email</label>
            <div class="control">
                <input class="input" [(ngModel)]="email" type="text" placeholder="Your email">
            </div>
            <p *ngFor="let error of errors" class="help">{{ error }}</p>
        </div>
        <div class="field">
            <label class="label">Password</label>
            <div class="control">
                <input class="input" [(ngModel)]="password" type="password" placeholder="Your password">
            </div>
            <p *ngFor="let error of errors" class="help">{{ error }}</p>
        </div>

        <div class="control">
            <button class="button is-primary" (click)="register()">Register</button>
        </div>
    </div>
</div>
