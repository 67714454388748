import { Component, OnInit } from '@angular/core';
import {QueryService} from "../../query/query";
import {ActivatedRoute, Router} from "@angular/router";
import {AccountService} from "../../query/account";
import {QueryBuilder} from "../../query/query-builder";
import {iAllow, iAudio, iAudioPackage} from "../../models/interfaces";
import {NgAudioRecorderService, OutputFormat} from "ng-audio-recorder";


@Component({
  selector: 'app-edit-audio-package',
  templateUrl: './edit-audio-package.component.html',
  styleUrls: ['./edit-audio-package.component.scss']
})
export class EditAudioPackageComponent implements OnInit {

  constructor(private query: QueryService, private route:ActivatedRoute,
              public account:AccountService, private router:Router, private audioRecorderService: NgAudioRecorderService) { }

  Object = Object;
  public audio_package_id;
  public audio_package:iAudioPackage;

  public soundsList:any = {};
  public mySoundsList:any = {};

  public showNotify:boolean = true;
  public updating:boolean = false;

  public recording:boolean = false;
  public errors:string[] = [];
  public allowEmail:string = '';
  public allows: iAllow[] = [];


  ngOnInit(): void {
    this.route.paramMap.subscribe( paramMap => {
      this.audio_package_id = parseInt(paramMap.get('audio_package_id'));
      this.account.current().then(res => {
        this.loadAudioPackage();
      });

    });
    this.audioRecorderService.recorderError.subscribe(recorderErrorCase => {
      // Handle Error
    });
  }

  loadAudioPackage(){
    this.query.one('audioPackage', this.audio_package_id, new QueryBuilder()
        .with('backancestors.audios.tag')
        .with('backancestors.audios.subTag')
        .with('backancestors.audios.character')
        .with('audios.tag')
        .with('audios.subTag')
        .with('audios.character')
        .with('game')
    ).then(res => {
      this.audio_package = res;
      this.calculateTagsSounds();
      this.calculateMyTags();
      this.getAllows();
    }).catch(res => {
      console.log('err audio package', res);
    });
  }

  calculateTagsSounds(){
    let lastPackageId = 0;
    for(let ancestor of this.audio_package.backancestors){
      for(let audio of ancestor.audios){

        if(!audio.tag){
          continue;
        }

        let tagname = audio.tag.title+(audio.sub_tag ? ('/'+audio.sub_tag.title) : '');
        if(audio.sub_tag){
          this.soundsList[tagname] = audio;
        } else {
          if(!this.soundsList[tagname] || lastPackageId != ancestor.audio_package_id){
            this.soundsList[tagname] = [];
            lastPackageId = ancestor.audio_package_id;
          }
          this.soundsList[tagname].push(audio);
        }
      }
    }
  }

  calculateMyTags(){
    for(let audio of this.audio_package.audios){
      let tagname = audio.tag.title+(audio.sub_tag ? ('/'+audio.sub_tag.title) : '');
      if(audio.sub_tag){
        this.mySoundsList[tagname] = audio;
      } else {
        if(!this.mySoundsList[tagname]){
          this.mySoundsList[tagname] = [];
        }
        this.mySoundsList[tagname].push(audio);
      }
    }
  }

  addSound(sound:iAudio, file){

    console.log('sound', sound);
    let tagname = sound.tag.title+(sound.sub_tag ? ('/'+sound.sub_tag.title) : '');
    this.updating = true;

    const newSound:iAudio = {
      user_id: this.account.user.user_id,
      audio_package_id: this.audio_package_id,
      file: '',
      character_id: sound.character_id,
      base_audio_id: sound.base_audio_id > 0 ? sound.base_audio_id : sound.audio_id,
      tag_id: sound.tag_id,
      sort: sound.sort,
      sub_tag_id: sound.sub_tag_id,
      tag: sound.tag,
      sub_tag: sound.sub_tag,
      character: sound.character
    } as iAudio;

    this.query.create('audio', {
      user_id: this.account.user.user_id,
      audio_package_id: this.audio_package_id,
      file: file,
      character_id: sound.character_id,
      base_audio_id: sound.base_audio_id > 0 ? sound.base_audio_id : sound.audio_id,
      tag_id: sound.tag_id,
      sort: sound.sort,
      sub_tag_id: sound.sub_tag_id
    }).then(res => {
      newSound.audio_id = res.audio_id;
      newSound.file = res.file;
      newSound.audio_package_id = res.audio_package_id;
      if(sound.sub_tag){
        this.mySoundsList[tagname] = newSound;
      } else {
        if(!this.mySoundsList[tagname]){
          this.mySoundsList[tagname] = [];
        }
        this.mySoundsList[tagname].push(newSound);
      }

      this.updating = false;

    }).catch(res => {
      console.log('res', res.error.error);
      this.errors = res.error.error;
      this.updating = false;
    });



  }

  deleteSound(sound: iAudio, index = 0){
    let tagname = sound.tag.title+(sound.sub_tag ? ('/'+sound.sub_tag.title) : '');
    this.updating = true;

    if(sound.audio_id){
      this.query.del('audio', sound.audio_id).then(res => {
        if(sound.sub_tag){
          delete this.mySoundsList[tagname];
        } else {
          this.mySoundsList[tagname].splice(index, 1) ;
        }
        this.updating = false;
      });
    } else {
      if(sound.sub_tag){
        delete this.mySoundsList[tagname];
      } else {
        this.mySoundsList[tagname].splice(index, 1) ;
      }
      this.updating = false;
    }
  }

  enablePackage(status:number){
    this.updating = true;
    this.query.update('audioPackage', this.audio_package.audio_package_id, {status: status}).then(res => {
      this.audio_package.status = res.status;
      this.updating = false;
    });
  }

  savePackage(){
    this.updating = true;
    this.query.update('audioPackage', this.audio_package.audio_package_id, this.audio_package).then(res => {
      this.updating = false;
    });
  }

  deletePackage(){
    this.query.del('audioPackage', this.audio_package.audio_package_id).then(res => {
      this.router.navigateByUrl('/game/'+this.audio_package.game_id);
    });
  }

  changeSound(sound:iAudio){
    this.query.update('audio', sound.audio_id, sound).then(res => {
      sound = res;
    });
  }

  startRecording() {
    this.audioRecorderService.startRecording();
    this.recording = true;
  }

  stopRecording(sound:iAudio) {
    this.audioRecorderService.stopRecording(OutputFormat.WEBM_BLOB).then((output) => {
      this.addSound(sound, output);
      this.recording = false;
    }).catch(errrorCase => {
      // Handle Error
      this.recording = false;
    });
  }



  getAllows(){
    this.query.all('allow', new QueryBuilder()
        .where('model', '=', 'AudioPackage')
        .where('model_id', '=', this.audio_package_id)
        .with('user')
    )
        .then(res => {
          this.allows = res.data;
        })
  }

  addAllow(email){
    this.query.call('allow', 'addAllow', {email: email, model:'AudioPackage', model_id: this.audio_package_id}).then(res => {
      this.getAllows();
      this.allowEmail = '';
    });
  }

  deleteAllow(allow:iAllow){
    this.query.del('allow', allow.allow_id).then(res => {
      this.getAllows();
    })
  }



}
