<nav class="breadcrumb" aria-label="breadcrumbs">
    <ul>
        <li><a href="/">Main</a></li>
        <li><a href="/games">Games</a></li>
        <li><a href="/game/{{ audio_package?.game_id }}">{{ audio_package?.game?.title }}</a></li>
        <li class="is-active"><a href="/edit-audio-package/{{ audio_package?.audio_package_id }}" aria-current="page">{{audio_package?.name}}</a></li>
    </ul>
</nav>


<h1 class="title">Edit audio package {{ audio_package?.name }}</h1>


<div class="notification is-warning is-light" *ngIf="showNotify">
    <button class="delete" (click)="showNotify = false;"></button>
    You don't have to add all the sounds at all.
    Change only the ones you want!
</div>

<div class="block" *ngIf="audio_package">

    <div class="field">
        <label class="label">Package title</label>
        <div class="control">
            <input class="input" type="text" [(ngModel)]="audio_package.name" placeholder="Social link">
        </div>
    </div>

    <div class="field">
        <label class="label">Link to your social network</label>
        <div class="control">
            <input class="input" type="text" [(ngModel)]="audio_package.link_to_you" placeholder="Social link">
        </div>
    </div>

    <div class="field">
        <label class="label">Donation link</label>
        <div class="control">
            <input class="input" type="text" [(ngModel)]="audio_package.payment_link" placeholder="Payment link">
        </div>
    </div>


    <div class="field">
        <label class="label">Additional data</label>
        <div class="control">
            <textarea class="textarea" [(ngModel)]="audio_package.additional_data" placeholder="Additional data"></textarea>
        </div>
    </div>

    <div class="field">
        <label class="label">Language code ('en', 'ru', 'fr', 'ch', 'de', etc)</label>
        <div class="control">
            <textarea class="textarea" [(ngModel)]="audio_package.language" placeholder="Additional data"></textarea>
        </div>
    </div>


    <div class="field is-grouped">
        <div class="control">
            <button class="button is-link" (click)="savePackage()">Save data</button>
        </div>
        <div class="control">
            <button class="button is-danger" [disabled]="audio_package.audios.length" (click)="deletePackage()">Delete package</button>
        </div>
    </div>



    <div class="buttons">
        <button class="button" [class.is-loading]="updating" title="Package enabled" (click)="enablePackage(1)" [disabled]="audio_package?.status == 1">Package enabled</button>
        <button class="button is-primary"  [class.is-loading]="updating" title="Package disabled" (click)="enablePackage(0)" [disabled]="audio_package?.status == 0">Package disabled</button>
    </div>
</div>

<div class="block">

    <div *ngFor="let tagSubtag of Object.keys(soundsList)" class="box">
        <h1 class="title">{{ tagSubtag }}</h1>
        <div *ngIf="soundsList[tagSubtag].length" class="columns">
            <div class="column">
                <h2 class="subtitle">Original sound</h2>
                <div *ngFor="let sound of soundsList[tagSubtag]">
                    <a class="button" *ngIf="!sound.show_player" (click)="sound.show_player = !sound.show_player">
                        Show player
                    </a>
                    <audio *ngIf="sound.show_player" [src]="sound.file" controls></audio>
                    <p>Extension: {{ sound.extension }}</p>
                    <p>Time: {{ sound.duration }} sec.</p>
                    <p *ngIf="sound.audio_text">Original text: {{ sound.audio_text }}</p>
                </div>
            </div>
            <div class="column">
                <h2 class="subtitle">Your sounds</h2>
                <div *ngFor="let sound of mySoundsList[tagSubtag];let i = index;" class="block">

                    <div class="field">
                        <label class="label">Sound</label>
                        <a class="button" *ngIf="!sound.show_player" (click)="sound.show_player = !sound.show_player">
                            Show player
                        </a>
                        <audio *ngIf="sound.show_player" [src]="sound.file" controls></audio>
                    </div>

                    <div class="field">
                        <label class="label">Text in audio</label>
                        <div class="control">
                            <textarea class="textarea" [(ngModel)]="sound.audio_text" (blur)="changeSound(sound)"></textarea>
                        </div>
                    </div>

                    <p>Time: {{ sound.duration }} sec.</p>

                    <a class="button is-danger" [class.is-loading]="updating" *ngIf="audio_package.iCanChange" (click)="deleteSound(sound, i)">Delete sound</a>
                </div>

                <span  *ngIf="audio_package.iCanChange" class="buttons">


                <div class="file" *ngIf="!recording">
                    <label class="file-label">
                        <span class="file-cta" (click)="startRecording()">
                          <span class="file-icon">
                            <i class="fas fa-microphone-alt"></i>
                          </span>
                          <span class="file-label">
                            Start recording
                          </span>
                        </span>
                    </label>
                </div>

                <div class="file" *ngIf="recording">
                    <label class="file-label">
                        <span class="file-cta" (click)="stopRecording(soundsList[tagSubtag][0])">
                          <span class="file-icon">
                            <i class="fas fa-microphone-alt-slash"></i>
                          </span>
                          <span class="file-label">
                            Stop recording
                          </span>
                        </span>
                    </label>
                </div>


                <div class="file" [class.is-loading]="updating">
                    <label class="file-label" >
                        <input class="file-input"  type="file" (change)="addSound(soundsList[tagSubtag][0], $event.target['files'].item(0))">
                        <span class="file-cta">
                          <span class="file-icon">
                            <i class="fas fa-upload"></i>
                          </span>
                          <span class="file-label">
                            Add sound
                          </span>
                        </span>
                    </label>
                </div>

                </span>

            </div>
        </div>
        <div *ngIf="!soundsList[tagSubtag].length" class="columns">
            <div class="column">
                <h2 class="subtitle">Original sound</h2>
                <a class="button" *ngIf="!soundsList[tagSubtag].show_player" (click)="soundsList[tagSubtag].show_player = !soundsList[tagSubtag].show_player">
                    Show player
                </a>
                <audio *ngIf="soundsList[tagSubtag].show_player" [src]="soundsList[tagSubtag].file" controls></audio>
                <p>Extension: {{ soundsList[tagSubtag].extension }}</p>
                <p>Time: {{ soundsList[tagSubtag].duration }} sec.</p>
                <p *ngIf="soundsList[tagSubtag].audio_text">Original text: {{ soundsList[tagSubtag].audio_text }}</p>
            </div>
            <div class="column" *ngIf="mySoundsList[tagSubtag]">
                <h2 class="subtitle">Your sound</h2>
                <div class="block">

                    <div class="field">
                        <label class="label">Sound</label>
                        <a class="button" *ngIf="!mySoundsList[tagSubtag].show_player" (click)="mySoundsList[tagSubtag].show_player = !mySoundsList[tagSubtag].show_player">
                            Show player
                        </a>
                        <audio *ngIf="mySoundsList[tagSubtag].show_player" [src]="mySoundsList[tagSubtag].file" controls></audio>
                    </div>

                    <div class="field">
                        <label class="label">Text in audio</label>
                        <div class="control">
                            <textarea class="textarea" [(ngModel)]="mySoundsList[tagSubtag].audio_text"  (blur)="changeSound(mySoundsList[tagSubtag])"></textarea>
                        </div>
                    </div>

                    <p>Time: {{ mySoundsList[tagSubtag].duration }} sec.</p>

                    <a class="button is-danger" [class.is-loading]="updating" *ngIf="audio_package.iCanChange" (click)="deleteSound(mySoundsList[tagSubtag])">Delete sound</a>
                </div>
            </div>
            <div class="column" *ngIf="audio_package.iCanChange && !mySoundsList[tagSubtag]">
                <h2 class="subtitle">Your sound</h2>

                <div class="file" *ngIf="!recording">
                    <label class="file-label">
                        <span class="file-cta" (click)="startRecording()">
                          <span class="file-icon">
                            <i class="fas fa-microphone-alt"></i>
                          </span>
                          <span class="file-label">
                            Start recording
                          </span>
                        </span>
                    </label>
                </div>

                <div class="file" *ngIf="recording">
                    <label class="file-label">
                        <span class="file-cta" (click)="stopRecording(soundsList[tagSubtag])">
                          <span class="file-icon">
                            <i class="fas fa-microphone-alt-slash"></i>
                          </span>
                          <span class="file-label">
                            Stop recording
                          </span>
                        </span>
                    </label>
                </div>

                <div class="file" [class.is-loading]="updating">
                    <label class="file-label">
                        <input class="file-input" type="file" (change)="addSound(soundsList[tagSubtag], $event.target['files'].item(0))">
                        <span class="file-cta">
                          <span class="file-icon">
                            <i class="fas fa-upload"></i>
                          </span>
                          <span class="file-label">
                            Select one sound
                          </span>
                        </span>
                    </label>
                </div>

            </div>
        </div>

    </div>

</div>

<div class="modal" [class.is-active]="errors.length">
    <div class="modal-background" (click)="errors = [];"></div>
    <div class="modal-content">
        <div class="box">
            <article class="media">
                <div class="media-content">
                    <p *ngFor="let err of errors">{{ err }}</p>
                </div>
            </article>
        </div>

    </div>
    <button class="modal-close is-large" aria-label="close" (click)="errors = [];"></button>
</div>


<div class="block box">

    <div class="block">
        <div class="field">
            <label class="label">Add user by email</label>
            <div class="control">
                <input class="input" type="text" placeholder="Email" [(ngModel)]="allowEmail">
            </div>
        </div>


        <div class="field is-grouped">
            <div class="control">
                <button class="button is-link" (click)="addAllow(allowEmail)">Add</button>
            </div>
        </div>
    </div>

    <div class="block" *ngFor="let allow of allows">
        <div class="columns">
            <div class="column">{{ allow.user.email }}</div>
            <div class="column"><a class="button" (click)="deleteAllow(allow)">Delete</a></div>
        </div>
    </div>
</div>