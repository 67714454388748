import { Component, OnInit } from '@angular/core';
import {QueryService} from "../../query/query";
import {ActivatedRoute, Router} from "@angular/router";
import {QueryBuilder} from "../../query/query-builder";
import {iAudio, iAudioPackage, iGame, iTag} from "../../models/interfaces";
import {AccountService} from "../../query/account";

@Component({
  selector: 'app-game',
  templateUrl: './game.component.html',
  styleUrls: ['./game.component.scss']
})
export class GameComponent implements OnInit {

  public game_id:number = 0;
  public game:iGame;
  public audioPackageName:string = '';

  constructor(private query: QueryService, private route:ActivatedRoute, public account:AccountService, private router: Router) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe( paramMap => {
      this.game_id = parseInt(paramMap.get('game_id'));
      this.loadGame(this.game_id);
    })
  }

  loadGame(game_id:number){
    let promise =
    this.query.one('game', game_id, new QueryBuilder()
        .with('audioPackages.audios.tag')
        .with('audioPackages.audios.subTag')
        .with('audioPackages.audios.character')
        .with('tags')
        .with('characters')
    );

      promise.then<iGame>(res => this.game = res);
      return promise;
  }

  createAudioPackage(audio_package: iAudioPackage){
    if(!this.audioPackageName){
      return;
    }
    const audioPackage = {
      user_id: this.account.user.user_id,
      game_id: audio_package.game_id,
      name: this.audioPackageName,
      audios: [] as iAudio[],
      base_audio_package_id: audio_package.audio_package_id
    } as iAudioPackage;

    this.query.create('audioPackage', audioPackage).then(res => {
      this.router.navigateByUrl('/edit-audio-package/'+res.audio_package_id);
    }).catch(res => {
      console.log('err audio package create', res);
    });

  }

  filterPackages(base_audio_package_id:number):iAudioPackage[]{
    return this.game.audio_packages.filter(a=> a.base_audio_package_id == base_audio_package_id);
  }

  deleteAudioPackage(audioPackage:iAudioPackage){
    this.query.del('audioPackage', audioPackage.audio_package_id).then(res => {
      this.loadGame(this.game_id);
    });
  }

  confirmPackage(audioPackage:iAudioPackage){
    this.query.call('audioPackage', 'confirmPackage', {audio_package_id: audioPackage.audio_package_id}).then(res => {
      this.loadGame(this.game_id);
    });
  }

  changeStatusPackage(audioPackage:iAudioPackage){
    this.query.call('audioPackage', 'changeStatus', {audio_package_id: audioPackage.audio_package_id}).then(res => {
      this.loadGame(this.game_id);
    });
  }

}
