import { Component, OnInit } from '@angular/core';
import {QueryService} from "../../query/query";
import {iGame} from "../../models/interfaces";
import {Router} from "@angular/router";
import {AccountService} from "../../query/account";

@Component({
  selector: 'app-games',
  templateUrl: './games.component.html',
  styleUrls: ['./games.component.scss']
})
export class GamesComponent implements OnInit {

  public search:string = '';
  public title:string = '';
  public games:iGame[];
  private timer:any;

  constructor(private query: QueryService, private router:Router, public account:AccountService) { }

  ngOnInit(): void {
    this.searchGame();
  }



  public searchGame(){
    if(this.timer){
      clearTimeout(this.timer);
      this.timer = null;
    }

    this.timer = setTimeout(() => {
      this.query.call('game', 'search', {search: this.search}).then(res => {
        this.games = res;
      });
    }, 200);

  }

  public createGame(){
    this.query.create('game', {title: this.title}).then(res => {
      // this.games.push(res);
      this.router.navigateByUrl('/initial-package/'+res['game_id']);
    });
  }

  public deleteGame(game:iGame){
    this.query.del('game', game.game_id).then(res => {
      this.searchGame();
    });
  }

}
