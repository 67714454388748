import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AudioPackagesListComponent } from './audio-packages-list/audio-packages-list.component';
import { GamesComponent } from './games/games.component';
import { AudiosComponent } from './audios/audios.component';
import { GameComponent } from './game/game.component';
import {QueryService} from "../query/query";
import {HttpClientModule} from "@angular/common/http";
import {AccountService} from "../query/account";
import { LoginRegisterComponent } from './login-register/login-register.component';
import {FormsModule} from "@angular/forms";
import { GameCreateComponent } from './game-create/game-create.component';
import { EditAudioPackageComponent } from './edit-audio-package/edit-audio-package.component';
import { DocumentationComponent } from './documentation/documentation.component';
import {NgAudioRecorderModule} from "ng-audio-recorder";

@NgModule({
  declarations: [
    AppComponent,
    AudioPackagesListComponent,
    GamesComponent,
    AudiosComponent,
    GameComponent,
    LoginRegisterComponent,
    GameCreateComponent,
    EditAudioPackageComponent,
    DocumentationComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    NgAudioRecorderModule
  ],
  providers: [QueryService, AccountService],
  bootstrap: [AppComponent]
})
export class AppModule { }
