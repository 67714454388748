<div class="content" >
    <h1>Установка в Unity</h1>
    <h2>Шаг 1. Регистрация игры</h2>
    <p>
        Добавьте пакет для Юнити в игру - <a href="https://audio.sa-wd.ru/storage/package/SAWDAudioManager.unitypackage">пакет</a><br>
        <br>
        Создайте пустой игровой объект SAWDAudioManager и добавьте скрипт SAWDAudioManager на него.<br>
        Пока что Юнити можно свернуть.<br>
        <br>
        Создайте игру в <a href="/games">Games</a> введя название.<br>
        Введите данные по игре - название (если хотите изменить :) ), описание, логотип.<br>
        Запомните ваш game ID.<br>
    </p>
    <h2>Шаг 2. Создание базовых пакетов</h2>
    <p>
        Создайте ваш базовый аудиопакет.<br>
        Каждый аудиопакет может содержать любое количество звуков, озвучек и чего угодно, что имеет звук.<br>
        Однако же мы предполагаем что вы в базовых аудиопакетах будете разделять наборы звуков по какой-нибудь логике.<br>
        <br>
        Например в AirHockeyVR пакетов два:<br>
        1. Туториал - озвучка обучения<br>
        2. Звуки шайб и локаций - просто заменяемые звуки игры<br>

        Добавьте тэги:<br>
        Для звуков, которые будут использоваться массивами создавайте просто тэг (например, это звуки ударов шайб).<br>
        Для звуков, каждый из которых должен быть заполнен индивидуально, создавайте сабтэг, основанный на главном тэге.<br>
        Для этого выберите основной тэг и откроется поле для создания сабтэгов. (например это озвучка обучения, записанная голосом)<br>
        <br>
        Одинарный клик по тэгу выбирает тэг для создания сабтэгов.<br>
        Двойной клик по тэгу позволяет переименовать тэг.<br>
        <br>
        Заполните список персонажей: в данный момент ничегго не делают, но предполагается что можно будет поменять одну озвучку персонажа на другую озвучу.<br>
    </p>
    <h2>Шаг 3. Создание базовых звуков</h2>
    <p>
        Здесь есть два варианта: добавить отдельный звук или залить звуки группой.<br>
        Если вы выберете тэги и сабтэги, то при заливке звуков они сразу будут созданы с этими тэгами и сабтэгами (ни тэг, ни сабтэг не обязательно выбирать при этом).<br>
        <br>
        Заполните все варианты звуков, для которых вы хотите создать возможность загружать другие варианты.<br>
        Вы не обязаны загружать здесь сами звуки, достаточно будет просто созданной строки, но когда другие озвучники будут делать перевод, они не смогут услышать оригинал, если вы не зальете звук.<br>
        <br>
        Заполните для каждого звука тэг и по необходимости сабтэг, так же опционально впишите текст озвучки.<br>
    </p>
    <h2>Шаг 4. Интеграция в Юнити</h2>
    <p>
        Возвращаемся в Юнити.<br>
        Впишите ваш Game ID в SAWDAudioManager, который вы создали в шаге 1.<br>
        <br>
        Что бы звуки изменялись в рантайме у нас есть два пути:<br>
        1. Там где один AudioSource с одним звуком - добавьте SAWDAudioSourceHelper, впишите Sound_tag - базовый тег и Sound_sub Tag - базовый сабтэг.<br>
        Укажите аудиосурс (хотя он подтянется автоматически, если он на этом же игровом объекте)<br>
        <br>
        2. Там где у вас много звуков, которые заполняются массивом, словарем или еще чем-то таким:<br>
        Откройте файл Example и посмотрите пример.<br>
        Вас интересуют только<br>
    </p>

        <pre>
SAWDAudioManager.SubscribeOnAudioLoad("Puck hit", ApplyNewAudios); // подписка на изменение звука (Puck hit - это глобальный тэг)
SAWDAudioManager.UnsubscribeAudioLoad("Puck hit", ApplyNewAudios); // отписка от изменения звука (Puck hit - это глобальный тэг)
        </pre>
    <p>
        и пример как получить список звуков.<br>
        <br>
        Для того, что бы заполнить ваш массив/словарь определите любую функцию-коллбэк. Эта функция будет вызвана каждый раз, когда загружены новые звуки и каждый раз, когда сделана подписка.<br>

    </p>
    <pre>
        private void ApplyNewAudios(List&lt;Audio&gt; audios)
        // пример для звуков шайб
        {{ "{" }}
            sounds = new AudioClip[audios.Count];
            for (int i = 0; i < audios.Count; i++ )
            {{ "{" }}
                sounds[i] = audios[i].clip;
            }

            // пример для туториала, где каждый звук - отдельный
            for (int i = 0; i&lt;audios.Count;i++)
            {{ "{" }}
                soundsDictionary[audios[i].sub_tag.title] = audios[i].clip;
            }

        }
    </pre>

    <p>
        Далее используйте ваши звуки так же, как вы их использовали раньше. Это просто массивы AudioClip.<br>
        Если у вас уже реализованы звуки в игре - все тоже самое, никак не меняйте ваш код, а только добавьте подписку на звуки так, что бы ваши звуки были заменены новыми загруженными звуками.
    </p>

    <h2>Шаг 5. Интеграция в юнити, часть 2</h2>
    <p>
        Что бы загрузить новый аудиопакет, пользователю нужен интерфейс с выбором аудиопакетов :)<br>

        Тут нам поможет блок из примера - AudioManagerUI (задизайнить его вам придется лично или берите такой какой он есть).<br>
        Просто посмотрите на сцену-пример - Example.<br>
<br>
        Изначальные пакеты заполняются в виде строки из айди через запятую в переменной SAWDAudioManager.audio_package_ids = "4,14,19"; // это тоже самое что скачать пакеты 4, 14 и 19<br>
        <br>
        Когда пользователь выбирает галочки - он просто заполняет эту строку айдишниками.<br>
        При клике по кнопке Apply - запускается загрузка звуков с сервера.<br>
    </p>


</div>
