<nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">
        <a class="navbar-item" href="/">
            <img src="/assets/logos/facebook_cover_photo_1.png">
        </a>

        <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
        </a>
    </div>

    <div id="navbarBasicExample" class="navbar-menu">
        <div class="navbar-start">

            <a href="/" class="navbar-item">
                Documentation
            </a>

            <a href="/games" class="navbar-item">
                Games
            </a>

<!--            <div class="navbar-item has-dropdown is-hoverable">-->
<!--                <a class="navbar-link">-->
<!--                    More-->
<!--                </a>-->

<!--                <div class="navbar-dropdown">-->
<!--                    <a class="navbar-item">-->
<!--                        About-->
<!--                    </a>-->
<!--                    <a class="navbar-item">-->
<!--                        Jobs-->
<!--                    </a>-->
<!--                    <a class="navbar-item">-->
<!--                        Contact-->
<!--                    </a>-->
<!--                    <hr class="navbar-divider">-->
<!--                    <a class="navbar-item">-->
<!--                        Report an issue-->
<!--                    </a>-->
<!--                </div>-->
<!--            </div>-->
        </div>

        <div class="navbar-end" *ngIf="!account.user">
            <div class="navbar-item">
                <div class="buttons">
                    <a href="/login" class="button is-primary">
                        <strong>Sign up or log in</strong>
                    </a>
                </div>
            </div>
        </div>

        <div class="navbar-end" *ngIf="account.user">
            <div class="navbar-item">
                <div class="buttons">
                    <a href="/profile" class="button is-primary">
                        <strong>Profile</strong>
                    </a>
                </div>
            </div>
        </div>

    </div>
</nav>

<router-outlet></router-outlet>