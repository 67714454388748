<nav class="level">
    <div class="level-left">
        <div class="field has-addons level-item">
            <div class="control">
                <input class="input" type="text" placeholder="Find a game" [(ngModel)]="search">
            </div>
            <div class="control">
                <a class="button is-info" (click)="searchGame()">
                    Search
                </a>
            </div>
        </div>
    </div>
    <div class="level-right" *ngIf="account.user">
        <div class="field has-addons level-item">
            <div class="control">
                <input class="input" type="text" placeholder="Game title" [(ngModel)]="title">
            </div>
            <div class="control">
                <a class="button is-info" (click)="createGame()">
                    Create game
                </a>
            </div>
        </div>
    </div>
</nav>




<div class="container-cards">
    <div class="card is-3" *ngFor="let game of games">
        <div class="card-image">
            <figure class="image is-4by3">
                <img [src]="game.photo" alt="{{ game.title }}">
            </figure>
        </div>
        <div class="card-content">
            <div class="media">
                <div class="media-content">
                    <p class="title is-4">{{game.title}}</p>
                    <p class="subtitle is-6">{{ game.created_at | date:'YYYY-MM-dd'}}</p>
                    <span><i class="fas fa-music"></i>{{ game.base_audio_packages_count }}</span>&nbsp;
                    <span><i class="fas fa-file-audio"></i>{{ game.audio_packages_count }}</span>
                </div>
            </div>

            <div class="content">
                {{ game.description }}
            </div>
        </div>
        <footer class="card-footer">
            <a href="/initial-package/{{game.game_id}}" *ngIf="game.iCanChange" class="card-footer-item">Edit game</a>
            <a href="/game/{{game.game_id}}" class="card-footer-item">Packages</a>
        </footer>
    </div>
</div>

