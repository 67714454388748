<nav class="breadcrumb" aria-label="breadcrumbs">
    <ul>
        <li><a href="/">Main</a></li>
        <li><a href="/games">Games</a></li>
        <li class="is-active"><a href="/initial-package/{{ game?.game_id }}" aria-current="page">{{game?.title}}</a></li>
    </ul>
</nav>



<h1 class="title">Game description / go to <a href="/game/{{game?.game_id}}">Game packages</a></h1>
<div class="block" *ngIf="game">

    <div class="field">
        Your game ID: {{ game.game_id }}
    </div>

    <div class="field">
        <label class="label">Title</label>
        <div class="control">
            <input class="input" type="text" placeholder="Game title" [(ngModel)]="game.title">
        </div>
    </div>


    <div class="field">
        <label class="label">Description</label>
        <div class="control">
            <textarea class="textarea" placeholder="Textarea" [(ngModel)]="game.description"></textarea>
        </div>
    </div>

    <div class="field">
        <label class="label">Game logo</label>
        <p class="image is-128x128">
            <img *ngIf="game.photo" [src]="game.photo">
        </p>
    </div>

    <div class="file is-primary">
        <label class="file-label">
            <input class="file-input" type="file"  (change)="game.photo = $event.target['files'].item(0)">
            <span class="file-cta">
      <span class="file-icon">
        <i class="fas fa-upload"></i>
      </span>
      <span class="file-label">
        Game logo
      </span>
    </span>
        </label>
    </div>


    <div class="field">
        <label class="label">Status</label>
        <div class="control">
            <div class="select">
                <select [(ngModel)]="game.status">
                    <option value="0">Non-visible</option>
                    <option value="1">Public</option>
                </select>
            </div>
        </div>
    </div>


    <div class="field is-grouped">
        <div class="control">
            <button class="button is-link" (click)="updateGame()">Save</button>
        </div>
        <div class="control">
            <button class="button is-danger" [disabled]="packages.length" (click)="deleteGame()">Delete</button>
        </div>
    </div>
</div>


<h1 class="title">Packages list</h1>
<nav class="level">
    <div class="level-left">
        <a class="button level-item" [class.is-active]="currentPackage?.audio_package_id == pack.audio_package_id" *ngFor="let pack of packages" (click)="currentPackage = pack;searchSounds();">{{pack.name}}</a>
    </div>
    <div class="level-right">
        <div class="field has-addons level-item">
            <div class="control">
                <input class="input" [(ngModel)]="name" type="text" placeholder="New audio package name">
            </div>
            <div class="control">
                <button class="button is-info" (click)="createBaseAudioPackage(name)">
                    Add
                </button>
            </div>
        </div>
    </div>
</nav>


<h1 class="title" *ngIf="currentPackage">Tags and characters</h1>
<div class="columns" *ngIf="currentPackage">
    <div class="column">

        <div class="box">
            <h2>Tags list</h2>
            <div class="field is-grouped is-grouped-multiline">
                <div class="control" *ngFor="let tag of filterTags(0)">
                    <div class="tags has-addons">
                        <span class="tag" [class.is-success]="searchTag?.tag_id == tag.tag_id" (click)="searchTag = searchTag?.tag_id == tag.tag_id ? null : tag;searchSounds();"><i class="fa fa-search" aria-hidden="true"></i></span>
                        <a class="tag is-link" [class.is-success]="currentTag?.tag_id == tag.tag_id" *ngIf="!tag.edit_mode" (dblclick)="tag.edit_mode = !tag.edit_mode" (click)="currentTag = currentTag?.tag_id == tag.tag_id ? null : tag; currentSubTag = null;searchSubTag = null;">{{ tag.title }}</a>
                        <a (click)="deleteTag(tag)" *ngIf="!tag.edit_mode" class="tag is-delete"></a>
                        <div class="field has-addons" *ngIf="tag.edit_mode">
                            <div class="control">
                                <input class="input is-small" [(ngModel)]="tag.title" type="text" placeholder="tag name">
                            </div>
                            <div class="control">
                                <a class="button is-info is-small" [class.is-loading]="tag.is_saving" (click)="saveTag(tag)">
                                    <i class="fas fa-save"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="field has-addons">
                <div class="control">
                    <input class="input is-small" [(ngModel)]="tagName" type="text" placeholder="Add tag">
                </div>
                <div class="control">
                    <a class="button is-info is-small" (click)="createTag()">
                        Add
                    </a>
                </div>
            </div>
        </div>

        <div class="box" *ngIf="currentTag">
            <h2>SubTags list for {{ currentTag.title }}</h2>
            <div class="field is-grouped is-grouped-multiline">
                <div class="control" *ngFor="let tag of filterTags(currentTag.tag_id)">
                    <div class="tags has-addons">
                        <span class="tag" [class.is-success]="searchSubTag?.tag_id == tag.tag_id" (click)="searchSubTag = searchSubTag?.tag_id == tag.tag_id ? null : tag;searchSounds();"><i class="fa fa-search" aria-hidden="true"></i></span>
                        <a class="tag is-link" [class.is-success]="currentSubTag?.tag_id == tag.tag_id" (click)="currentSubTag = currentSubTag?.tag_id == tag.tag_id ? null : tag" *ngIf="!tag.edit_mode" (dblclick)="tag.edit_mode = !tag.edit_mode">{{ tag.title }}</a>
                        <a (click)="deleteTag(tag)" *ngIf="!tag.edit_mode" class="tag is-delete"></a>
                        <div class="field has-addons" *ngIf="tag.edit_mode">
                            <div class="control">
                                <input class="input is-small" [(ngModel)]="tag.title" type="text" placeholder="tag name">
                            </div>
                            <div class="control">
                                <a class="button is-info is-small" [class.is-loading]="tag.is_saving" (click)="saveTag(tag)">
                                    <i class="fas fa-save"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="field has-addons">
                <div class="control">
                    <input class="input is-small" [(ngModel)]="tagName" type="text" placeholder="Add sub tag">
                </div>
                <div class="control">
                    <a class="button is-info is-small" (click)="createTag(currentTag.tag_id)">
                        Add
                    </a>
                </div>
            </div>
        </div>

    </div>
    <div class="column">
        <div class="box">
            <h2>Characters list</h2>
            <div class="field is-grouped is-grouped-multiline">
                <div class="control" *ngFor="let character of characters">
                    <div class="tags has-addons">
                        <span class="tag" [class.is-success]="searchCharacter?.character_id == character.character_id" (click)="searchCharacter = searchCharacter?.character_id == character.character_id ? null : character;searchSounds();"><i class="fa fa-search" aria-hidden="true"></i></span>
                        <a class="tag is-link" [class.is-success]="currentCharacter?.character_id == character.character_id" (click)="currentCharacter = currentCharacter?.character_id == character.character_id ? null : character" *ngIf="!character.edit_mode" (dblclick)="character.edit_mode = !character.edit_mode">{{ character.name }}</a>
                        <a (click)="deleteCharacter(character)" *ngIf="!character.edit_mode" class="tag is-delete"></a>
                        <div class="field has-addons" *ngIf="character.edit_mode">
                            <div class="control">
                                <input class="input is-small" [(ngModel)]="character.name" type="text" placeholder="character name">
                            </div>
                            <div class="control">
                                <a class="button is-info is-small"  [class.is-loading]="character.is_saving" (click)="saveCharacter(character)">
                                    <i class="fas fa-save"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="field has-addons">
                <div class="control">
                    <input class="input is-small" [(ngModel)]="characterName" type="text" placeholder="Add character">
                </div>
                <div class="control">
                    <a class="button is-info is-small" (click)="createCharacter()">
                        Add
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>


<h1 class="title">Package sounds</h1>
<nav class="level" *ngIf="currentPackage">
    <div class="level-left">
        <div class="level-item is-grouped-multiline">
            <div class="field has-addons">
                <div class="control">
                    <input class="input" type="text" [(ngModel)]="currentPackage.language" placeholder="language: en, ru, de, etc">
                </div>
                <div class="control">
                    <a class="button is-info" (click)="savePackage(currentPackage)">
                        Save language
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="level-right">
        <div class="level-item">
            <div class="buttons">
                <button class="button" [class.is-danger]="currentPackage.status" [class.is-success]="!currentPackage.status" (click)="enablePackage(currentPackage)">{{ currentPackage.status ? "Disable" : "Enable" }} "{{ currentPackage.name }}" package</button>
                <button class="button is-danger" (click)="deletePackage(currentPackage)" [disabled]="currentPackage.audios?.length">Delete "{{ currentPackage.name }}" package</button>
            </div>
        </div>
    </div>
</nav>

<nav class="level" *ngIf="currentPackage">
    <div class="level-left">
        <div class="level-item">
            <div class="field ">
                <p class="control">
                    <a class="button" (click)="createSound(currentPackage)">Add new empty sound</a>
                </p>
            </div>
        </div>
    </div>
    <div class="level-right">
        <div class="level-item">
            <div class="field ">
                <p class="control">
                    <label class="file-label">
                        <input class="file-input" type="file" [multiple]="!currentSubTag" (change)="createSound(currentPackage, $event.target['files'])">
                        <span class="file-cta">
                          <span class="file-icon">
                            <i class="fas fa-upload"></i>
                          </span>
                          <span class="file-label">
                            {{ currentSubTag ? 'Upload one audio with' : 'Upload audios with' }} {{ currentTag?.title || "no tag" }} / {{ currentSubTag?.title || "no sub tag" }} / {{ currentCharacter?.name || "no character" }}
                          </span>
                        </span>
                    </label>
                </p>
            </div>
        </div>
    </div>

</nav>

<div class="box" *ngIf="currentPackage">

        <article class="media" *ngFor="let audio of currentPackage.audios;let i = index;">
            <div class="block columns is-vcentered" *ngIf="!audio.loading">
                <div class="column">
                    <div class="file block is-warning is-light">
                        <label class="file-label">
                            <input class="file-input" type="file" (change)="audio.file = $event.target['files'].item(0)">
                            <span class="file-cta">
                          <span class="file-icon">
                            <i class="fas fa-upload"></i>
                          </span>
                          <span class="file-label">
                            Upload audio
                          </span>
                        </span>
                        </label>
                    </div>

                    <div class="file block" *ngIf="!recording">
                        <label class="file-label">
                        <span class="file-cta" (click)="startRecording()">
                          <span class="file-icon">
                            <i class="fas fa-microphone-alt"></i>
                          </span>
                          <span class="file-label">
                            Start recording
                          </span>
                        </span>
                        </label>
                    </div>

                    <div class="file block" *ngIf="recording">
                        <label class="file-label">
                        <span class="file-cta" (click)="stopRecording(audio)">
                          <span class="file-icon">
                            <i class="fas fa-microphone-alt-slash"></i>
                          </span>
                          <span class="file-label">
                            Stop recording
                          </span>
                        </span>
                        </label>
                    </div>

                    <div class="block">{{ audio.client_file_name }}</div>
                    <button class="button block" *ngIf="!audio.show_player && audio.file" (click)="audio.show_player = !audio.show_player"><i class="fa fa-play" aria-hidden="true"></i></button>
                    <audio class="block" *ngIf="audio.show_player" [src]="audio.file" controls></audio>
                </div>

                <div class="column">
                    <div class="field">
                        <label class="label">Base tag</label>
                        <div class="control">
                            <select class="select" [(ngModel)]="audio.tag_id">
                                <option *ngFor="let tag of filterTags(0)" [value]="tag.tag_id">{{ tag.title }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="field has-addons">
                        <div class="control">
                            <input class="input is-small" [(ngModel)]="tagName" type="text" placeholder="Add tag">
                        </div>
                        <div class="control">
                            <a class="button is-info is-small" (click)="createTag()">
                                add tag
                            </a>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Sub tag</label>
                        <div class="control">
                            <select class="select" [(ngModel)]="audio.sub_tag_id">
                                <optgroup label="Multiply sounds in one tag">
                                    <option value="0">(Empty)</option>
                                </optgroup>
                                <optgroup label="One subtag - one sound">
                                    <option *ngFor="let tag of filterTags(audio.tag_id)" [value]="tag.tag_id">{{ tag.title }}</option>
                                </optgroup>
                            </select>
                        </div>
                    </div>
                    <div class="field has-addons">
                        <div class="control">
                            <input class="input is-small" [(ngModel)]="tagName" type="text" placeholder="Add sub tag">
                        </div>
                        <div class="control">
                            <a class="button is-info is-small" (click)="createTag(audio.tag_id)">
                                add subtag
                            </a>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label">Character</label>
                        <div class="control">
                            <select class="select" [(ngModel)]="audio.character_id">
                                <option value="0">None</option>
                                <option *ngFor="let character of characters" [value]="character.character_id">{{ character.name }}</option>
                            </select>
                        </div>
                    </div>
                </div>


                <div class="column is-2">
                    <div class="field">
                        <label class="label">Sound text</label>
                        <div class="control">
                            <textarea class="textarea" [(ngModel)]="audio.audio_text" placeholder="text in audio (optional)"></textarea>
                        </div>
                    </div>
                </div>

                <div class="column is-1">
                    <div class="field">
                        <label class="label">File extension</label>
                        <div class="control">
                            <input class="input" type="text" [(ngModel)]="audio.extension" placeholder="wav (without dot!)">
                        </div>
                    </div>
                </div>

                <div class="column is-1">
                    <div class="field">
                        <label class="label">Sort</label>
                        <div class="control">
                            <input class="input" type="text" [(ngModel)]="audio.sort" placeholder="number">
                        </div>
                    </div>
                </div>


                <div class="column">
                    <div class="buttons">
                        <button class="button" (click)="saveSound(audio, i, currentPackage)">Save sound</button>
                        <button class="button is-danger" (click)="deleteSound(audio, i, currentPackage)">Delete sound</button>
                    </div>
                </div>

            </div>

            <div class="block" *ngIf="audio.loading">
                <div class="notification is-danger">
                    File still loading!
                    <progress class="progress is-small is-primary" max="100">15%</progress>
                </div>
            </div>


        </article>

</div>


<div class="box">

    <div class="block">
        <div class="field">
            <label class="label">Add user by email</label>
            <div class="control">
                <input class="input" type="text" placeholder="Email" [(ngModel)]="allowEmail">
            </div>
        </div>


        <div class="field is-grouped">
            <div class="control">
                <button class="button is-link" (click)="addAllow(allowEmail)">Add</button>
            </div>
        </div>
    </div>

    <div class="block" *ngFor="let allow of allows">
        <div class="columns">
            <div class="column">{{ allow.user.email }}</div>
            <div class="column"><a class="button" (click)="deleteAllow(allow)">Delete</a></div>
        </div>
    </div>
</div>

