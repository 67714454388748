import { Component, OnInit } from '@angular/core';
import {AccountService} from "../../query/account";
import {QueryService} from "../../query/query";
import {QueryBuilder} from "../../query/query-builder";
import {ActivatedRoute, Router} from "@angular/router";
import {iAllow, iAudio, iAudioPackage, iCharacter, iGame, iTag} from "../../models/interfaces";
import {NgAudioRecorderService, OutputFormat} from "ng-audio-recorder";

@Component({
  selector: 'app-game-create',
  templateUrl: './game-create.component.html',
  styleUrls: ['./game-create.component.scss']
})
export class GameCreateComponent implements OnInit {

  public game_id:number = 0;
  public game: iGame;
  public name:string = '';
  public tagName:string = '';
  public characterName:string = '';
  public packages:iAudioPackage[] = [];
  public currentPackage:iAudioPackage;
  public tags:iTag[] = [];
  public characters:iCharacter[] = [];
  public currentTag:iTag;
  public currentSubTag:iTag;
  public currentCharacter: iCharacter;

  public searchCharacter:iCharacter;
  public searchTag:iTag;
  public searchSubTag:iTag;

  public allows:iAllow[];
  public allowEmail:string = '';

  public recording:boolean = false;

  constructor(private account:AccountService, private query:QueryService,private route:ActivatedRoute, private router:Router, private audioRecorderService: NgAudioRecorderService) {
    this.route.paramMap.subscribe( paramMap => {
      this.game_id = parseInt(paramMap.get('game_id'));
      this.account.current().then(res => {
        this.loadGame();
        // this.loadCharacters();
        // this.loadTags();
        // this.loadBasePackages();
      });

    })
  }

  ngOnInit(): void {

  }

  loadGame(){
    this.query.one('game', this.game_id, new QueryBuilder().with('tags').with('characters').with('baseAudioPackages'))
        .then(res => {
      this.game = res as iGame;
      this.tags = this.game.tags;
      this.characters = this.game.characters;
      this.packages = this.game.base_audio_packages;
      this.getAllows();

      if(this.packages[0]){
        this.currentPackage = this.packages[0];
        this.searchSounds();
      }

    });
  }


  getAllows(){
    this.query.all('allow', new QueryBuilder()
        .where('model', '=', 'Game')
        .where('model_id', '=', this.game_id)
        .with('user')
    )
        .then(res => {
      this.allows = res.data;
    })
  }

  addAllow(email){
    this.query.call('allow', 'addAllow', {email: email, model:'Game', model_id: this.game.game_id}).then(res => {
      this.getAllows();
      this.allowEmail = '';
    });
  }

  deleteAllow(allow:iAllow){
    this.query.del('allow', allow.allow_id).then(res => {
      this.getAllows();
    })
  }

  updateGame(){
    this.query.update('game', this.game.game_id, {
      title: this.game.title,
      description: this.game.description,
      photo: this.game.photo,
      status: this.game.status
    }).then(res => {
      this.game = res;
    });
  }

  enablePackage(audioPackage:iAudioPackage){
    this.query.update('audioPackage', audioPackage.audio_package_id, {status: audioPackage.status ? 0 : 1}).then(res => {
      this.currentPackage.status = res['status'];
    });
  }

  savePackage(audioPackage:iAudioPackage){
    this.query.update('audioPackage', audioPackage.audio_package_id, {language: audioPackage.language ? audioPackage.language : ""}).then(res => {
      this.currentPackage.language = res['language'];
    });
  }

  filterTags(parent_tag_id:number):iTag[]{
    return this.tags.filter(a=> a.parent_tag_id == parent_tag_id && this.currentPackage?.audio_package_id == a.audio_package_id);
  }

  createBaseAudioPackage(name: string){
    this.query.call('audioPackage', 'createBasePackage', {name: name, game_id: this.game_id}).then(res => {
      res.audios = [];
      this.packages.push(res);
      this.currentPackage = this.packages[this.packages.length-1];
      this.name = '';
    });
  }

  createCharacter(){
    this.query.create('character', {name: this.characterName, game_id: this.game_id}).then(res => {
      this.loadCharacters();
      this.characterName = '';
    });
  }

  createTag(parent_tag_id: number = 0){
    this.query.create('tag', {title: this.tagName, game_id: this.game_id, parent_tag_id: parent_tag_id, audio_package_id: this.currentPackage.audio_package_id}).then(res => {
      this.loadTags();
      this.tagName = '';
    });
  }

  loadBasePackages(){
    this.query.all('audioPackage', new QueryBuilder()
        .where('game_id', '=', this.game_id)
        .where('base_audio_package_id', '=', 0)
    ).then(res => {
      this.packages = res['data'];
      if(this.packages[0]){
        this.currentPackage = this.packages[0];
        this.searchSounds();
      } else {
        this.currentPackage = null;
      }
    });
  }

  searchSounds(){
    let qb = new QueryBuilder();
    if(this.searchCharacter){
      qb.where('character_id', '=', this.searchCharacter.character_id)
    }
    if(this.searchTag){
      qb.where('tag_id', '=', this.searchTag.tag_id);
    }
    if(this.searchSubTag){
      qb.where('sub_tag_id', '=', this.searchSubTag.tag_id);
    }
    if(this.currentPackage){
      qb.where('audio_package_id', '=', this.currentPackage.audio_package_id);
    }
    qb.per_page(99999);
    this.query.all('audio', qb).then(res => {
      this.currentPackage.audios = res['data'];
    });
  }

  loadTags(){
    this.query.all('tag', new QueryBuilder().where('game_id', '=', this.game_id)).then(res => {
      this.tags = res['data'];
    });
  }

  loadCharacters(){
    this.query.all('character', new QueryBuilder().where('game_id', '=', this.game_id)).then(res => {
      this.characters = res['data'];
    });
  }

  saveTag(tag:iTag){
    tag.is_saving = true;
    this.query.update('tag', tag.tag_id, tag).then(res => {
      tag.edit_mode = false;
      tag.is_saving = false;
    });
  }

  saveCharacter(character:iCharacter){
    character.is_saving = true;
    this.query.update('character', character.character_id, character).then(res => {
      character.edit_mode = false;
      character.is_saving = false;
    });
  }

  deleteTag(tag:iTag){
    this.query.del('tag', tag.tag_id).then(res => {
      this.loadTags();
    });
  }

  deleteCharacter(character:iCharacter){
    this.query.del('character', character.character_id).then(res => {
      this.loadCharacters();
    });
  }

  deletePackage(audioPackage:iAudioPackage){
    this.query.del('audioPackage', audioPackage.audio_package_id).then(res => {
      this.loadBasePackages();
    });
  }

  createSound(audio_package:iAudioPackage, files:FileList = null){

    if(files && files.length){
      for(let f=0;f<files.length;f++){

        let audio = {
          audio_package_id: audio_package.audio_package_id,
          game_id: audio_package.game_id,
          file:files.item(f),
          character_id: this.currentCharacter?.character_id || 0,
          tag_id: this.currentTag?.tag_id || 0,
          sort: 0,
          sub_tag_id: this.currentSubTag?.tag_id || 0,
          user_id: this.account.user.user_id,
          base_audio_id: 0,
          audio_id: 0,
          extension: '',
          show_player: false,
          loading: true
        } as iAudio;

        this.saveSound(audio, audio_package.audios.length-1, audio_package);
      }
    } else {
      if(!audio_package.audios){
        audio_package.audios = [];
      }
      audio_package.audios.push({
        audio_package_id: audio_package.audio_package_id,
        game_id: audio_package.game_id,
        file:'',
        character_id: this.currentCharacter?.character_id || 0,
        tag_id: this.currentTag?.tag_id || 0,
        sort: 0,
        sub_tag_id: this.currentSubTag?.tag_id || 0,
        user_id: this.account.user.user_id,
        base_audio_id: 0,
        audio_id: 0,
        extension: '',
        show_player: false
      } as iAudio);
    }


  }

  saveSound(sound:iAudio, index, audio_package:iAudioPackage){

    if(sound.audio_id){
      sound.loading = true;
      this.query.update('audio', sound.audio_id, sound).then(res => {
        res.loading = false;
        if(index < 0){
          audio_package.audios.push(res);
        } else {
          for(let audio in audio_package.audios){
            if(sound.audio_id == audio_package.audios[audio].audio_id){
              audio_package.audios[audio] = res;
              return;
            }
          }
        }

      });
    } else {
      sound.loading = true;
      this.query.create('audio', sound).then(res => {
        res.loading = false;
        if(index < 0){
          audio_package.audios.push(res);
        } else {
          audio_package.audios[index] = res;
        }
      })
    }
  }

  deleteSound(sound:iAudio, index, audio_package:iAudioPackage){
    sound.loading = true;
    if(sound.audio_id){
      this.query.del('audio', sound.audio_id).then(res => {
        for(let audio in audio_package.audios){
          if(sound.audio_id == audio_package.audios[audio]?.audio_id){
            audio_package.audios.splice(parseInt(audio), 1);
            return;
          }
        }

      });
    } else {
      audio_package.audios.splice(index, 1);
    }
  }

  deleteGame(){
    this.query.del('game', this.game.game_id).then(res => {
      this.router.navigateByUrl('/games');
    });
  }


  startRecording() {
    this.audioRecorderService.startRecording();
    this.recording = true;
  }

  stopRecording(sound:iAudio) {
    this.audioRecorderService.stopRecording(OutputFormat.WEBM_BLOB).then((output) => {
      sound.file = output;
      this.recording = false;
    }).catch(errrorCase => {
      // Handle Error
      this.recording = false;
    });
  }


}
