<nav class="breadcrumb" aria-label="breadcrumbs">
    <ul>
        <li><a href="/">Main</a></li>
        <li><a href="/games">Games</a></li>
        <li class="is-active"><a href="/game/{{ game?.game_id }}" aria-current="page">{{game?.title}}</a></li>
    </ul>
</nav>



<div *ngIf="game">
    <h1 class="title">Packages of {{ game.title }} | <a *ngIf="game?.iCanChange" href="/initial-package/{{game?.game_id}}"> go to game config</a></h1>

    <div class="box" *ngFor="let audioPackage of filterPackages(0)">
        <h2 class="subtitle">Base package <i class="blue">{{ audioPackage.name }}</i> - <b class="red">{{ audioPackage.language }}</b></h2>

        <div class="columns">
            <div class="column">
                <h2>Sounds list</h2>
                <table class="table">
                    <tr>
                        <td>File</td>
                        <td>Tag / Subtag</td>
                        <td>Character</td>
                        <td>Sort</td>
                    </tr>
                    <tr *ngFor="let audio of audioPackage.audios">
                        <td><a class="clickable" *ngIf="!audio.show_player" (click)="audio.show_player = true;">Show player</a><audio *ngIf="audio.show_player" controls [src]="audio.file"></audio></td>
                        <td>{{ [audio.tag?.title, audio.sub_tag?.title].join('/') }}</td>
                        <td>{{ audio.character?.name }}</td>
                        <td>{{ audio.sort }}</td>
                    </tr>
                </table>
            </div>
            <div class="column" *ngIf="account.user">
                <h2>Actions</h2>

                <div class="field has-addons" *ngIf="audioPackage.audios.length">
                    <div class="control">
                        <input class="input" [(ngModel)]="audioPackageName" type="text" placeholder="New package name">
                    </div>
                    <div class="control">
                        <a class="button is-info" (click)="createAudioPackage(audioPackage)">
                            Create audio package based on this package
                        </a>
                    </div>
                </div>

                <div class="buttons">
                    <a *ngIf="audioPackage.iCanChange && audioPackage.base_audio_package_id > 0" class="button is-warning" href="/edit-audio-package/{{audioPackage.audio_package_id}}">or Edit this audio package</a>
                    <a *ngIf="audioPackage.iCanChange && !audioPackage.base_audio_package_id" class="button is-warning" href="/initial-package/{{ audioPackage.game_id }}">or Edit this audio package</a>
                    <button *ngIf="audioPackage.iCanConfirm && audioPackage.audios.length" class="button" [class.is-danger]="audioPackage.confirmed_by_dev" [class.is-success]="!audioPackage.confirmed_by_dev" (click)="confirmPackage(audioPackage)">{{ audioPackage.confirmed_by_dev ? "Hide Package" : "Approve package" }}</button>
                    <button *ngIf="audioPackage.iCanChange && audioPackage.audios.length" class="button" [class.is-danger]="audioPackage.status == 1" [class.is-success]="audioPackage.status == 0" (click)="changeStatusPackage(audioPackage)">{{ audioPackage.status == 1 ? "Disable package" : "Enable package" }}</button>
                </div>

            </div>
        </div>





        <div class="box" *ngFor="let audioPackageSub of filterPackages(audioPackage.audio_package_id)">
            <h2 class="subtitle">Sub package <i class="blue">{{ audioPackageSub.name }}</i> - <b class="red">{{ audioPackageSub.language }}</b></h2>

            <div class="columns">
                <div class="column">
                    <h2 class="title">Description</h2>
                    <div class="content">
                        <p>{{ audioPackageSub.additional_data }}</p>
                        <a target="_blank" *ngIf="audioPackageSub.link_to_you && audioPackageSub.link_to_you.indexOf('http') != -1" [href]="audioPackageSub.link_to_you">User link</a>
                        <p *ngIf="audioPackageSub.link_to_you && audioPackageSub.link_to_you.indexOf('http') == -1" >User: {{ audioPackageSub.link_to_you }}</p>
                        <hr *ngIf="audioPackageSub.link_to_you && audioPackageSub.payment_link">
                        <a target="_blank" *ngIf="audioPackageSub.payment_link && audioPackageSub.payment_link.indexOf('http') != -1" [href]="audioPackageSub.payment_link">Donation link</a>
                        <p *ngIf="audioPackageSub.payment_link && audioPackageSub.payment_link.indexOf('http') == -1" >Donations: {{ audioPackageSub.payment_link }}</p>
                    </div>

                    <h2 class="title">Sounds list</h2>
                    <table class="table">
                        <tr>
                            <td>File</td>
                            <td>Tag / Subtag</td>
                            <td>Character</td>
                            <td>Sort</td>
                        </tr>
                        <tr *ngFor="let audio of audioPackageSub.audios">
                            <td><a class="clickable" *ngIf="!audio.show_player" (click)="audio.show_player = true;">Show player</a><audio *ngIf="audio.show_player" controls [src]="audio.file"></audio></td>
                            <td>{{ [audio.tag?.title, audio.sub_tag?.title].join('/') }}</td>
                            <td>{{ audio.character?.name }}</td>
                            <td>{{ audio.sort }}</td>
                        </tr>
                    </table>
                </div>
                <div class="column">
                    <h2>Actions</h2>

<!--                    <div class="field has-addons">-->
<!--                        <div class="control">-->
<!--                            <input class="input" [(ngModel)]="audioPackageName" type="text" placeholder="New package name">-->
<!--                        </div>-->
<!--                        <div class="control">-->
<!--                            <a class="button is-info" (click)="createAudioPackage(audioPackage)">-->
<!--                                Create audio package based on this package-->
<!--                            </a>-->
<!--                        </div>-->
<!--                    </div>-->

                    <div class="buttons">
                        <a *ngIf="audioPackageSub.iCanChange && audioPackageSub.base_audio_package_id > 0" class="button  is-warning" href="/edit-audio-package/{{audioPackageSub.audio_package_id}}">Edit this audio package</a>
                        <a *ngIf="audioPackageSub.iCanChange && !audioPackageSub.base_audio_package_id" class="button  is-warning" href="/initial-package/{{ audioPackageSub.game_id }}">Edit this audio package</a>
                        <button *ngIf="audioPackageSub.iCanChange" [disabled]="audioPackageSub.audios.length" class="button is-danger" (click)="deleteAudioPackage(audioPackageSub)">Delete this audio package</button>
                        <button *ngIf="audioPackageSub.iCanConfirm" class="button" [class.is-danger]="audioPackageSub.confirmed_by_dev" [class.is-success]="!audioPackageSub.confirmed_by_dev" (click)="confirmPackage(audioPackageSub)">{{ audioPackageSub.confirmed_by_dev ? "Hide Package" : "Approve package" }}</button>
                        <button *ngIf="audioPackageSub.iCanChange && audioPackageSub.audios.length" class="button" [class.is-danger]="audioPackageSub.status == 1" [class.is-success]="audioPackageSub.status == 0" (click)="changeStatusPackage(audioPackageSub)">{{ audioPackageSub.status == 1 ? "Disable package" : "Enable package" }}</button>
                    </div>

                </div>
            </div>

        </div>





    </div>

</div>


