import { Component, OnInit } from '@angular/core';
import {AccountService} from "../../query/account";
import {Router} from "@angular/router";
import {iGame, iUser} from "../../models/interfaces";

@Component({
  selector: 'app-login-register',
  templateUrl: './login-register.component.html',
  styleUrls: ['./login-register.component.scss']
})
export class LoginRegisterComponent implements OnInit {

  public email:string = '';
  public name:string = '';
  public password:string = '';
  public save:boolean = false;
  public errors:any = [];


  constructor(public account:AccountService, private router:Router) { }

  ngOnInit(): void {
    this.account.current();
  }

  login(){
    this.account.login({email:this.email, password:this.password}, this.save).then(res => {
      this.router.navigateByUrl('/games');
    }).catch(res => {
      console.log(res);
      this.errors = res.error.email;
    });
  }

  register(){
    this.account.register({email:this.email, password:this.password, name: this.name}).then(res => {
      this.router.navigateByUrl('/games');
    }).catch(res => {
      this.errors = res.error.email;
    })
  }

}
