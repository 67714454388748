import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AudioPackagesListComponent} from "./audio-packages-list/audio-packages-list.component";
import {GamesComponent} from "./games/games.component";
import {GameComponent} from "./game/game.component";
import {LoginRegisterComponent} from "./login-register/login-register.component";
import {EditAudioPackageComponent} from "./edit-audio-package/edit-audio-package.component";
import {GameCreateComponent} from "./game-create/game-create.component";
import {DocumentationComponent} from "./documentation/documentation.component";

const routes: Routes = [
  {path: 'packages', component:AudioPackagesListComponent},
  {path: 'games', component:GamesComponent},
  {path: 'game/:game_id', component: GameComponent},
  {path: 'edit-audio-package/:audio_package_id', component: EditAudioPackageComponent},
  {path: 'initial-package/:game_id', component: GameCreateComponent},
  {path: 'login', component: LoginRegisterComponent},
  {path: '', component: DocumentationComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
